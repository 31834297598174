html, body, #root {
  height: 100%
}

body {
	min-height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}